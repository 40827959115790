.customContainer{
  max-width: 100% !important;
  background-color: #081013;
  height: 100vh;
}

.imgFinish{
  width: 240px;
  margin: 0 auto;
}

.textFinish {
  font-family: 'Oxygen-Regular';
  margin: 0 0 15px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-bottom: 2px;
}

.text{
  font-family: 'Oxygen-Regular';
  color: #BCBDC1;
  font-size: 14px;
  margin-bottom: 25px;
}

.contentFinishBtns{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  margin-bottom: 40px;
}

.btnIcon{
  background-color: #FFFF83;
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnStartAgain{
  background-color: #57585C;
  border-radius: 10px;
  width: 480px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 15px;
}

.txtBtnIcon{
  font-family: 'Oxygen-Bold';
  color: #081013;
  font-size: 16px;
}

.txtBtnAgain{
  font-family: 'Oxygen-Bold';
  color: #fff;
  font-size: 16px;
}

.rowIconPhone{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.phoneIcon{
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(../../assets/images/icons/phoneIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.rowSubmit{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconPhone{
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/icons/phoneIcon.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.iconCalendar{
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/icons/calendarIcon.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

@media (max-width: 1366px){
  .imgFinish{
    width: 176px;
  }

  .btnStartAgain{
    width: 435px;
  }

  .contentFinishBtns{
    width: 435px;
  }

  .btnIcon{
    width: 200px;
  }

  .iconPhone, .iconCalendar{
    margin-right: 3px;
  }

}

@media (max-width: 480px){
  .txtBtn{
    font-size: 14px;
  }

  .contentFinishBtns{
    flex-direction: column;
    margin-bottom: 20px;
  }

  .btnIcon{
    margin-bottom: 20px;
  }

  .btnStartAgain{
    width: 200px;
  }
}