.customContainer{
  max-width: 100% !important;
  background-color: #081013;
  height: 100vh;
}

.bgImage{
  background-image: url(../../assets/images/bgHome.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.imgSize{
  width: 240px;
  margin: 0 auto;
}

.txtPeople{
  font-family: 'FiraSans-LightItalic';
  color: #ADADAD;
  font-size: 18px;
}

.subtitleRoom {
  font-family: 'FiraSans-Bold';
  margin: 0 0 15px;
  text-align: center;
  color: #fff;
  font-size: 28px;
}

.text{
  font-family: 'Oxygen-Regular';
  color: #BCBDC1;
  font-size: 14px;
  margin-bottom: 25px;
}

.btnStart{
  background-color: #fff;
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 100px;
  cursor: pointer;
}

.btnStart:hover{
  background-color: #BCBDC1;
}

.btnStart:active{
  background-color: #57585C;
}

.btnStart:active .txtBtn{
  color: #fff;
}

.txtBtn{
  font-family: 'Oxygen-Bold';
  color: #081013;
  font-size: 16px;
}

@media (max-width: 1366px){
  .imgSize{
    width: 100%;
  }

  .btnStart{
    margin: 40px auto 30px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .imgSize{
    width: 100%;
  }
  .btnStart{
    width: 100px;
  }
  .txtPeople{
    font-size: 17px;
  }

  .subtitleRoom{
    font-size: 22px;
    padding: 0 12%;
  }

  .txtBtn{
    font-size: 14px;
  }
}