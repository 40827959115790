.headerContent {
  margin: 0;
  padding: 0;
  background: rgb(35,37,44);
  background: linear-gradient(90deg, rgba(35,37,44,0) 0%, rgba(35,37,44,1) 50%, rgba(35,37,44,0) 100%);
  width: 100%;
  height: 100px;
}

.customRow {
  align-items: center;
  height: 100%;
}

.contentLogo {
  margin-left: 25%;
  height: 40px;
  background-image: url(../../assets/images/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.sideMobile{
  width: 40px;
  height: 40px;
  background-image: url(../../assets/images/icons/menuIcon.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.title{
  font-family: 'FiraSans-Bold';
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 48px;
}

@media (max-width: 1366px){
  .title{
    font-size: 36px;
  }

  .headerContent{
    height: 85px;
  }
}

@media (max-width: 600px){
  .headerContent{
    height: 140px;
  }

  .title{
    font-size: 28px;
  }

  .contentLogo {
    margin: 10px 26% 0;
  }

  .sideMobile{
    display: block;
  }
}