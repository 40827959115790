.customContainer{
  max-width: 100% !important;
  background-color: #081013;
  height: 100vh;
}

.bgImage{
  background-image: url(../../assets/images/bgHome.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.titleRoom {
  font-family: 'Oxygen-Regular';
  margin: 0 0 15px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-bottom: 2px;
}

.text{
  font-family: 'Oxygen-Regular';
  color: #BCBDC1;
  font-size: 14px;
  margin-bottom: 25px;
}

.btnSubmit{
  background-color: #fff;
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnSubmit:hover{
  background-color: #BCBDC1;
}

.btnSubmit:active{
  background-color: #57585C;
}

.btnSubmit:active .txtBtn {
  color: #fff;
}

.txtBtn{
  font-family: 'Oxygen-Bold';
  color: #081013;
  font-size: 16px;
}

.customInput{
  font-family: 'Oxygen-Regular';
  height: 60px !important;
  border-radius: 10px !important;
  color: #ADADAD !important;
  font-size: 14px !important;
  margin-bottom: 30px;
}

.customInput::-webkit-input-placeholder {
  color: #ADADAD !important;
}

.txtTerms{
  font-family: 'Oxygen-Regular';
  color: #fff;
  font-size: 10px;
  margin-left: 5px;
}

input[type="checkbox"]{
  width: 17px;
  height: 17px;
  border-radius: 3px;
}

.rowIconPhone{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.phoneIcon{
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(../../assets/images/icons/phoneIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.textPhone{
  font-family: 'FiraSans-Bold';
  font-size: 32px;
  color: #F3F3F3;
  margin-bottom: 0;
}

.textExperts{
  font-family: 'FiraSans-Regular';
  font-size: 26px;
  color: #BCBDC1;
  margin-bottom: 5px;
}

.rowSubmit{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spaceExperts {
  justify-content: 'center';
  margin-top: 60px;
}

@media (max-width: 1366px){
  .customInput{
    height: 55px !important;
    margin-bottom: 10px;
  }

  .textExperts{
    font-size: 18px;
  }

  .textPhone{
    font-size: 22px;
  }

  .spaceExperts{
    margin-top: 30px;
  }

  .btnSubmit{
    width: 200px;
  }
}

@media screen and (max-width: 480px) {
  .slideMenuCol{
    position: absolute !important;
    top: 0;
    padding-top: 0;
  }

  .imgSize{
    width: 100%;
  }
  .btnStart{
    width: 100px;
  }
  .txtPeople{
    font-size: 17px;
  }

  .txtBtn{
    font-size: 14px;
  }
}