.customContainer{
  max-width: 100% !important;
  background-color: #081013;
  height: 100vh;
}

.slideMenuCol{
  padding: 60px 0px 0 0;
}

.imgOps{
  width: 100%;
  margin: 0 auto;
}

.contentDescription{
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  height: auto;
  padding: 40px 35px 10px;
}

.mainText{
  font-family: 'FiraSans-Bold';
  font-size: 28px;
  color: #23252C;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 2px;
}

.parentText {
  font-family: 'FiraSans-BoldItalic';
  font-size: 22px;
  color: #23252C;
}

.secondText{
  font-family: 'Oxygen-Regular';
  font-size: 14px;
  color: #333333;
}

.txtPeople{
  font-family: 'FiraSans-LightItalic';
  color: #ADADAD;
  font-size: 18px;
}

.secondSubtitle {
  font-family: 'FiraSans-Bold';
  margin: 25px 0 15px;
  text-align: center;
  color: #fff;
  font-size: 28px;
}

.textIcon{
  font-family: 'Oxygen-Regular';
  color: #BCBDC1;
  font-size: 14px;
  margin-bottom: 0;
}

.btnWhite{
  background-color: #fff;
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 30px;
  cursor: pointer;
}

.btnWhite:hover{
  background-color: #BCBDC1;
}

.btnWhite:active{
  background-color: #57585C;
}

.btnWhite:active .txtBtn {
  color: #fff;
}

.txtBtn{
  font-family: 'Oxygen-Bold';
  color: #081013;
  font-size: 16px;
}

.rowIcons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
}

.playIcon{
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/playIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.playText{
  font-family: 'Oxygen-Regular';
  color: #081013;
  margin-bottom: 0;
  font-size: 14px;
}

.centerWhite{
  display: flex;
  align-items: center;
}

.contentBtns{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}

.topOPS{
  padding-top: 40px;
}

@media (max-width: 1600px){
  .slideMenuCol{
    padding-top: 30px;
  }

  .contentDescription{
    width: 100%;
  }
}

@media (max-width: 1440px){
  .mainText{
    font-size: 22px;
  }

  .parentText{
    font-size: 18px;
  }

  .contentBtns{
    width: 80%;
  }
}

@media (max-width: 1366px){
  .contentDescription{
    padding: 20px 35px 10px;
  }

  .mainText{
    font-size: 18px;
  }

  .parentText {
    font-size: 14px;
  }

  .secondText{
    font-size: 12px;
  }

  .rowIcons{
    margin-bottom: 0;
  }

  .playText{
    font-size: 12px;
  }

  .secondSubtitle{
    font-size: 24px;
  }

  .btnWhite{
    width: 200px;
  }

}

@media (max-width: 480px){
  .slideMenuCol{
    position: absolute !important;
    top: 0;
    padding-top: 0;
  }

  .txtBtn{
    font-size: 14px;
  }

  .topOPS{
    padding-top: 0;
  }

  .imgOps{
    width: 70%;
  }

  .contentDescription{
    padding: 20px 35px 40px;
  }

  .mainText{
    font-size: 22px;
  }

  .secondSubtitle{
    font-size: 22px;
    padding: 0 15%;
  }

  .contentBtns{
    width: 100%;
  }

  .btnWhite{
    width: 160px;
  }
}