.contentSteps{
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.activeStep{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #BCBDC1;
}

.step{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #BCBDC1;
  position: relative;
}

.blackpoint{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #081013;
  position: absolute;
}

@media (max-width: 480px){
  .contentSteps{
    width: 60%;
    margin-bottom: 30px;
  }
}