.contentTip{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.iconTip{
  width: 60px;
  height: 22px;
  background-image: url(../../assets/images/icons/tipIcon.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.txtTip{
  font-family: 'Oxygen-Regular';
  margin-bottom: 0;
  margin-left: 15px;
  color: #BCBDC1;
  font-size: 14px;
}

.slideMenuColMo{
  padding: 60px 0px 0 0;
}

.spaceRowTop{
  margin-top: 60px;
}

@media (max-width: 1600px){
  .slideMenuColMo{
    padding-top: 30px;
  }

  .spaceRowTop{
    margin-top: 30px;
  }
}

@media (max-width: 1366px){
  .imgSize{
    width: 100%;
  }
}

@media (max-width: 480px){
  .slideMenuColMo{
    position: absolute !important;
    top: 0;
    padding-top: 0;
  }

  .subtitle {
    font-size: 22px;
    padding: 0 10%;
  }

  .txtTip{
    width: 50%;
  }

  .txtBtn{
    font-size: 14px;
  }

  .contentTip{
    margin-top: 40px;
  }
}