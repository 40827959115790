.customContainer{
  max-width: 100% !important;
  background-color: #081013;
  height: 100vh;
}

.imgScreen{
  width: 260px;
  margin: 0 auto;
}

.txtPeople{
  font-family: 'FiraSans-LightItalic';
  color: #ADADAD;
  font-size: 18px;
}

.subtitleType {
  font-family: 'FiraSans-Bold';
  margin: 0 0 15px;
  text-align: center;
  color: #fff;
  font-size: 28px;
}

.textIcon{
  font-family: 'Oxygen-Regular';
  color: #BCBDC1;
  font-size: 14px;
  margin-bottom: 0;
}

.btnScreen{
  background-color: #fff;
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 100px;
  cursor: pointer;
}

.btnScreen:hover{
  background-color: #BCBDC1;
}

.btnScreen:active{
  background-color: #57585C;
}

.btnScreen:active .txtBtn {
  color: #fff;
}

.txtBtn{
  font-family: 'Oxygen-Bold';
  color: #081013;
  font-size: 16px;
}

.rowIcons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
}

.playIcon{
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/playIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.slideMenuColType{
  padding: 60px 0px 0 0;
}

.modal-content{
  margin-top: 30%;
  background-color: #23252C !important;
  border-radius: 20px !important;
}

.modal-body{
  background-color: #23252C;
  border-radius: 20px;
}

.closeIcon{
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #23252C;
  font-weight: bold;
  font-family: 'Oxygen-Bold';
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  z-index: 999;
  right: -10px;
  top: -10px
}

@media (max-width: 1600px){
  .slideMenuColType{
    padding-top: 30px;
  }
}

@media (max-width: 1366px){
  .imgScreen{
    width: 80%;
  }

  .btnScreen{
    margin: 40px auto 30px;
    width: 200px;
  }
}

@media (max-width: 480px){
  .slideMenuColType{
    position: absolute !important;
    top: 0;
    padding-top: 0;
  }

  .subtitleType{
    font-size: 22px;
    padding: 0 20%;
  }

  .imgScreen{
    width: 100%;
    margin-top: 20px;
  }

  .btnScreen{
    width: 100%;
  }

  .txtBtn{
    font-size: 14px;
  }
}

@media (max-width: 360px){
  .subtitleType{
    padding: 0 16%;
  }
}