/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Custom Fonts */

@font-face {
  font-family: 'FiraSans-Bold';
  src: local('FiraSans-Bold'), url(./assets/fonts/FiraSans-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-BoldItalic';
  src: local('FiraSans-BoldItalic'), url(./assets/fonts/FiraSans-BoldItalic.otf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Book';
  src: local('FiraSans-Book'), url(./assets/fonts/FiraSans-Book.otf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-LightItalic';
  src: local('FiraSans-LightItalic'), url(./assets/fonts/FiraSans-LightItalic.otf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Regular';
  src: local('FiraSans-Regular'), url(./assets/fonts/FiraSans-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Oxygen-Bold';
  src: local('Oxygen-Bold'), url(./assets/fonts/Oxygen-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Oxygen-Regular';
  src: local('Oxygen-Regular'), url(./assets/fonts/Oxygen-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oxygen-Light';
  src: local('Oxygen-Light'), url(./assets/fonts/Oxygen-Light.ttf) format('truetype');
}