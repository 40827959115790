.customContainer{
  max-width: 100% !important;
  background-color: #081013;
  height: 100vh;
}

.homeTop{
  padding-top: 60px;
}

.bgImage{
  background-image: url(../../assets/images/bgHome.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.bgGroup{
  width: 240px;
  margin: 0 auto;
}

.subtitle{
  font-family: 'FiraSans-Bold';
  margin: 0 0 15px;
  text-align: center;
  color: #fff;
  font-size: 28px;
}

.text{
  font-family: 'Oxygen';
  font-weight: 400;
  color: #BCBDC1;
  padding: 0 10%;
  font-size: 14px;
}

.btnHome{
  background-color: #fff;
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 100px;
  cursor: pointer;
}

.btnHome:hover{
  background-color: #BCBDC1;
}

.btnHome:active{
  background-color: #57585C;
}

.btnHome:active .txtBtn {
  color: #fff;
}

.txtBtn{
  font-family: 'Oxygen-Bold';
  color: #081013;
  font-size: 16px;
}

.txtBtn:active{
  color: #fff;
}

@media (max-width: 1366px){
  .bgGroup{
    width: 180px;
  }

  .btnHome{
    margin: 40px auto 30px;
    width: 200px;
  }
}

@media (max-width: 480px){
  .homeTop{
    padding-top: 30px;
  }

  .btnHome{
    width: 160px;
  }

  .txtBtn{
    font-size: 14px;
  }
}

@media (max-width: 360px){
  .homeTop{
    padding-top: 0px;
  }
}