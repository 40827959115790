.contentSide{
  background: linear-gradient(90deg, #57585C 0%, #23252C 50%, #23252C 100%);
  padding: 25px 0 25px 25px;
  border-radius: 10px 0 0 10px;
  width: 80%;
  float: right;
  min-height: 610px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  border-bottom: 1px solid #57585C;
  margin: 15px 0;
}

.titleSide{
  font-family: 'FiraSans-Bold';
  font-size: 18px;
  color: #fff;
  margin-left: 20px;
}

.imgIconSide{
  width: 70px;
  height: 70px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.customFlex{
  display: flex;
  align-items: center;
}

.txtTitleSide{
  font-family: 'FiraSans-Bold';
  margin-bottom: 0;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
}

.txtDescSide{
  font-family: 'FiraSans-Book';
  margin-bottom: 0;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
}

.contentTexts{
  width: 40%;
}

.editIcon{
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/icons/editIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 35px;
  cursor: pointer;
}

.logosMobile {
  display: none;
}

@media (max-width: 1366px){
  .contentSide{
    width: 95%;
    min-height: 512px;
    padding: 25px 0 20px 20px;
  }

  .imgIconSide{
    width: 55px;
    height: 55px;
    margin-right: 10px;
  }

  li {
    margin: 8px 0;
  }

}

@media screen and (max-width: 480px){
  .contentSide{
    min-height: calc(100vh + 100px);
    border-radius: 0px;
    width: 80%;
  }

  .logosMobile{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  .logoMobile{
    width: 184px;
    height: 42px;
  }

  .closeMobile{
    width: 23px;
    height: 23px;
  }
}