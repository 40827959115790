@media (max-width: 480px){
  body{
    background-color: #081013 !important;
  }

  .customContainer{
    height: auto !important;
  }
}

